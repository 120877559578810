import Box from "@material-ui/core/Box";
import Image from "next/image";
import Link from "next/link";
import routes from "routes";

const ratio = 2.94;
const defaultWidth = 132;

const Linkify = ({ children }) => (
  <Link href={routes.HOME}>
    <Box style={{ display: "flex", cursor: "pointer" }}>
      {children}
    </Box>
  </Link>
);

const LogoPositiveFilled = ({ size, noRedirect, ...rest }) => {
  if (noRedirect) {
    return (
      <Image
        src="/assets/logos/logo-positive-filled.svg"
        height={(size || defaultWidth) / ratio}
        width={size || defaultWidth}
        objectFit="contain"
        {...rest}
      />
    );
  }
  return (
    <Linkify>
      <Image
        src="/assets/logos/logo-positive-filled.svg"
        height={(size || defaultWidth) / ratio}
        width={size || defaultWidth}
        objectFit="contain"
        {...rest}
      />
    </Linkify>
  );
};

export default LogoPositiveFilled;
