import APIEndpoints from "./APIEndpoints";
import hit from "./hit";

const get = (filters) => hit(APIEndpoints.QUESTIONS.GET(filters));

const create = (questions) => hit(APIEndpoints.QUESTIONS.CREATE, questions);

export default {
  create,
  get,
};
