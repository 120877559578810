import APIEndpoints from "./APIEndpoints";
import hit from "./hit";

const join = (showId) => hit(APIEndpoints.SHOWS.JOIN(showId));
const answer = (showId, body, ticket) => (
  hit(APIEndpoints.SHOWS.ANSWER(showId), body, null, { "x-show-encrypted-ticket": ticket })
);
const consumeLife = (showId, ticket) => (
  hit(APIEndpoints.SHOWS.CONSUME_LIFE(showId), null, null, { "x-show-encrypted-ticket": ticket })
);

const terminate = (showId) => (
  hit(APIEndpoints.SHOWS.TERMINATE(showId))
);

const begin = (showId) => (
  hit(APIEndpoints.SHOWS.BEGIN(showId))
);

const reset = (showId) => (
  hit(APIEndpoints.SHOWS.RESET(showId))
);

const update = (showId, changes) => (
  hit(APIEndpoints.SHOWS.UPDATE(showId), changes)
);

const reveal = (showId) => (
  hit(APIEndpoints.SHOWS.REVEAL(showId))
);

const rate = (showId, rating) => (
  hit(APIEndpoints.SHOWS.RATE(showId), rating)
);

const streamQuestion = (showId, questionId) => (
  hit(APIEndpoints.SHOWS.EMIT_QUESTION(showId), { questionId })
);

const revealQuestion = (showId) => (
  hit(APIEndpoints.SHOWS.REVEAL_QUESTION(showId))
);

const create = (data) => (
  hit(APIEndpoints.SHOWS.CREATE, data)
);

const sendAlert = (showId, data) => (
  hit(APIEndpoints.SHOWS.ALERT(showId), data)
);

export default {
  rate,
  join,
  reset,
  begin,
  update,
  answer,
  create,
  reveal,
  terminate,
  sendAlert,
  consumeLife,
  streamQuestion,
  revealQuestion,
};
