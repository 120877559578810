import APIEndpoints from "./APIEndpoints";
import hit from "./hit";

const pushApp = ({ body, title }) => hit(APIEndpoints.PUSH_NOTIFICATION, {
  body,
  title,
});

export default {
  pushApp,
};
