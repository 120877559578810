import querystring from "query-string";

const APIEndpoints = Object.freeze({
  SESSION: {
    REFRESH: { method: "POST", url: "/sessions/tokens", authentication: true },
    GET: (token) => ({ method: "get", url: `/sessions/${token}` }),
    CREATE: {
      WITH_GOOGLE: {
        method: "post",
        url: "/sessions/google",
      },
      WITH_FACEBOOK: {
        method: "post",
        url: "/sessions/facebook",
      },
    },
  },
  USERS: {
    GET_CASHOUTS: { method: "GET", url: "/users/me/cashouts", authentication: true },
    WITHDRAW_CASH: { method: "POST", url: "/users/me/cashouts", authentication: true },
    HARD_DELETE: { method: "DELETE", url: "/users/me", authentication: true },
    SET_REFERRAL: { method: "PATCH", url: "/users/me/referredBy", authentication: true },
    UPDATE: { method: "PATCH", url: "/users/me", authentication: true },
    PHONE_CHALLENGE: { method: "POST", url: "/users/me/phone-challenges", authentication: true },
    PHONE_VERIFICATION: { method: "POST", url: "/users/me/phone-verification", authentication: true },
    RANKINGS: {
      BY_WINS: { method: "get", url: "/users/rankings/by-wins", authentication: true },
      BY_CASH_EARNED: { method: "get", url: "/users/rankings/by-cash-earned", authentication: true },
      BY_REFERRALS: { method: "get", url: "/users/rankings/by-referrals", authentication: true },
    },
  },
  INSIDERS: {
    GET: (filters) => ({ method: "get", url: `/insiders?${querystring.stringify(filters)}`, authentication: true }),
    CREATE: { method: "POST", url: "/insiders", authentication: true },
  },
  QUESTIONS: {
    GET: (filters) => ({ method: "get", url: `/questions?${querystring.stringify(filters)}`, authentication: true }),
    CREATE: { method: "POST", url: "/questions", authentication: true },
  },
  ASSETS: {
    CREATE_SIGNED_URL_FOR_SHOW: { method: "POST", url: "/assets/pre-signed-urls", authentication: true },
  },
  SHOWS: {
    GET_FULL_BY_ID: (id) => ({ method: "get", url: `/shows/${id}/full`, authentication: true }),
    CREATE: { method: "POST", url: "/shows", authentication: true },
    CONSUME_LIFE: (id) => ({ method: "POST", url: `/shows/${id}/participants/consumed-lifes`, authentication: true }),
    BEGIN: (id) => ({ method: "POST", url: `/shows/${id}/beginning`, authentication: true }),
    GET_WINNERS: (id) => ({ method: "get", url: `/shows/${id}/winners`, authentication: true }),
    RESET: (showId) => ({ method: "POST", url: `/shows/${showId}/reset`, authentication: true }),
    TERMINATE: (showId) => ({ method: "POST", url: `/shows/${showId}/termination`, authentication: true }),
    UPDATE: (showId) => ({ method: "PATCH", url: `/shows/${showId}`, authentication: true }),
    REVEAL: (showId) => ({ method: "POST", url: `/shows/${showId}/reveal`, authentication: true }),
    REVEAL_QUESTION: (showId) => ({ method: "POST", url: `/shows/${showId}/questions/reveals`, authentication: true }),
    ALERT: (showId) => ({ method: "POST", url: `/shows/${showId}/alerts`, authentication: true }),
    EMIT_QUESTION: (showId) => ({ method: "POST", url: `/shows/${showId}/questions/streams`, authentication: true }),
    RATE: (showId) => ({ method: "POST", url: `/shows/${showId}/rates`, authentication: true }),
    GET_LIST: { method: "get", url: "/shows" },
    NEXT: { method: "get", url: "/shows/next" },
    GET_BY_ID: (id) => ({ method: "get", url: `/shows/${id}` }),
    ANSWER: (showId) => ({ method: "POST", url: `/shows/${showId}/answers`, authentication: true }),
    JOIN: (showId) => ({ method: "POST", url: `/shows/${showId}/participants`, authentication: true }),
  },
  AB: {
    SHARE_CODE: (userId, group) => ({ method: "POST", url: `/event?userId=${userId}&groupId=${group}&testId=4`, overrideBaseUrl: process.env.NEXT_PUBLIC_NOTIFICATIONS_URL, authentication: true }),
    JOIN_WITH_CODE: (userId, group) => ({ method: "POST", url: `/event?userId=${userId}&groupId=${group}&testId=5`, overrideBaseUrl: process.env.NEXT_PUBLIC_NOTIFICATIONS_URL, authentication: true }),
  },
  LATEST_COMPATIBLE_VERSION: { method: "get", url: "/minimum-client-version" },
  PUSH_NOTIFICATION: { method: "PUT", url: "/push-notification?channelId=13", overrideBaseUrl: process.env.NEXT_PUBLIC_NOTIFICATIONS_URL, authentication: true },
});

export default APIEndpoints;
