import { createContext, useContext, useMemo, useEffect, useState } from "react";
import { useAppState } from "store/AppState";
import { sessions, saveToken } from "api";
import { parseJsonOrNull, calculateGroup } from "utils";
import * as Sentry from "@sentry/nextjs";

export const SessionContext = createContext({
  loading: true,
  isLoggedIn: null,
  data: null,
  error: null,
  refresh: () => null,
  group: null,
  update: async () => null,
});

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const { state: { loading }, setState } = useAppState();
  const [session, setSession] = useState({
    data: null,
    error: null,
  });

  const refresh = async () => {
    const savedSession = parseJsonOrNull(localStorage.getItem("session"));
    if (savedSession) {
      saveToken(savedSession.accessToken);
      const { error, ...data } = await sessions.refresh();
      if (!error) {
        // setLanguage(data.languageId);
        const group = calculateGroup(data.user.username);
        localStorage.setItem("session", JSON.stringify(data));
        saveToken(data.accessToken);
        setSession({
          error: null,
          data,
          group,
        });
        setState((s) => ({
          ...s,
          loading: false,
        }));
        Sentry.setUser({
          id: data.user.id,
          username: data.user.username,
        });
        return;
      }
      localStorage.removeItem("session");
    }
    setSession({
      error: true,
      data: null,
    });
    setState((s) => ({
      ...s,
      loading: false,
    }));
  };

  useEffect(() => {
    if (loading) refresh();
  }, [loading]);
 
  const sessionLoading = useMemo(() => (
    !session.error && !session.data
  ), [session]);

  const activeSession = useMemo(() => ({
    ...session,
    loading: sessionLoading,
    refresh,
    isLoggedIn: sessionLoading ? null : (!!session.data && !session.error),
  }));

  return (
    <SessionContext.Provider value={activeSession}>
      {children}
    </SessionContext.Provider>
  );
};

export const withSession = (Component) => (props) => (
  <SessionProvider>
    <Component {...props} />
  </SessionProvider>
);
