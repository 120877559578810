import APIEndpoints from "./APIEndpoints";
import hit from "./hit";

const joinWithCode = (userId, group) => hit(APIEndpoints.AB.JOIN_WITH_CODE(userId, group));

const shareCode = (userId, group) => hit(APIEndpoints.AB.SHARE_CODE(userId, group));

export default {
  joinWithCode,
  shareCode,
};
