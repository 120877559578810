import { createContext, useContext, useMemo, useState } from "react";

export const AppStateContext = createContext({
  state: {
    hasHistory: false,
    loading: true,
  },
  cache: {
    likes: {},
    follows: {},
    bookmarks: {},
  },
  setState: () => {},
  setCache: () => {},
});

export const useAppState = () => useContext(AppStateContext);

export const AppStateProvider = ({ children }) => {
  const [appState, setState] = useState({
    loading: true,
  });
  const [cache, setCache] = useState({
    likes: {},
    follows: {},
    bookmarks: {},
  });
  
  const state = useMemo(() => ({
    state: appState,
    setState,
    setCache,
    cache,
  })
  , [appState, setCache]);

  return (
    <AppStateContext.Provider value={state}>
      {children}
    </AppStateContext.Provider>
  );
};

export const withAppState = (Component) => (props) => (
  <AppStateProvider>
    <Component {...props} />
  </AppStateProvider>
);
