import APIEndpoints from "./APIEndpoints";
import hit from "./hit";

const createWithFacebook = hit.bind(this, APIEndpoints.SESSION.CREATE.WITH_FACEBOOK);
const createWithGoogle = hit.bind(this, APIEndpoints.SESSION.CREATE.WITH_GOOGLE);
const refresh = () => hit(APIEndpoints.SESSION.REFRESH);
const get = (token) => hit(APIEndpoints.SESSION.GET(token));

export default {
  createWithFacebook,
  createWithGoogle,
  refresh,
  get,
};
